<template>
  <b-card title="Project Warranty List">
    <b-modal v-model="modalDelete" title="Delete Project Warranty">
      <!-- Alert before Delete-->
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="d-flex">
              Are you sure want to delete this {{ selectProject.name }} ?
            </div>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <b-button variant="danger" class="ml-1" @click="doDelete"
            >Confirm</b-button
          >
        </div>
      </template>
    </b-modal>

    <b-col cols="12">
      <b-row class="mb-2">
        <b-col cols="4">
          <b-button v-if="permission.add" variant="success" @click="addProject">
            Add Project
          </b-button>
        </b-col>
        <b-col cols="2"></b-col>
        <b-col cols="6">
          <b-form-input
            v-model="search"
            @input="(value) => nameFiltering(value)"
            type="search"
            placeholder="Type to Search"
          />
          <span style="font-size: smaller; color: gray;"
            >Note: Please omit the last character of R or D of project warranty
            number (example: 2024062501285975AC)</span
          >
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12">
      <b-table :fields="fields" :items="projects" show-empty responsive>
        <template #cell(topDate)="row">
          {{ row.item.topDate | formatDate }}
        </template>
        <template #cell(action)="row">
          <b-dropdown
            boundary="window"
            offset="-50"
            variant="primary"
            text="Menu"
            no-caret
          >
            <template #button-content>
              <feather-icon size="1x" icon="MenuIcon" />
            </template>
            <b-dropdown-item style="text-align:center" v-if="permission.edit">
              <b-button
                size="sm"
                variant="outline-primary"
                @click="doEditProject(row.item._id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-dropdown-item>
            <b-dropdown-item style="text-align:center" v-if="permission.delete">
              <b-button
                variant="danger"
                size="sm"
                @click="deleteProject(row.item)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-dropdown-item>

            <b-dropdown-item active @click="letterDev(row.item._id)">
              Print Warranty for Developer
            </b-dropdown-item>
            <b-dropdown-item active @click="letterResident(row.item._id)">
              Print Warranty for Residential
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-col>
    <b-col cols="12">
      <b-pagination
        :total-rows="totalRows"
        :per-page="perPage"
        v-model="currentPage"
        style="float: right"
        @change="onPageChange"
      />
    </b-col>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "@/axios";
import { userAccess, downloadByPDFMake } from "@/utils/utils";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";
import {
  logo,
  footer,
  header,
} from "../../delivery-module/actions-detail/daikin-img-base64";
import lodash from "lodash";

export default {
  data() {
    return {
      fields: [
        { key: "name", text: "Name", filterable: true, sortable: true },
        { key: "address", text: "Address" },
        { key: "topDate", text: "Top Date" },
        { key: "developer", text: "Developer" },
        { key: "email", text: "Email", filterable: true, sortable: true },
        {
          key: "refNum",
          text: "Cert Number",
          filterable: true,
          sortable: true,
        },
        { key: "action", text: "Action" },
      ],
      currentPage: 1,
      totalRows: 1,
      perPage: 10,
      search: "",
      selectProject: "",
      modalDelete: false,
      getDataProject: "",
      daikinHeader: require("@/assets/images/drcc/Daikin_Header.png"),
      daikinFooter: require("@/assets/images/drcc/Daikin_Footer.png"),
    };
  },
  mounted() {
    this.$store.dispatch("TermCondition/fetchTermCondition");
    this.queryProjectWarranty();
    console.log("term", this.termsCondition);
  },
  methods: {
    ...mapActions({
      getAllProjects: "projectWarranty/getProjectsWarranty",
      deleteProject: "projectWarranty/deleteProject",
      getProjectWarranty: "projectWarranty/getProject",
    }),
    nameFiltering: lodash.debounce(function(value) {
      this.search = value.trim();
      this.currentPage = 1;
      this.queryProjectWarranty();
    }, 500),
    onPageChange(page) {
      this.currentPage = page;
      this.queryProjectWarranty();
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.tableLoading = false;
    },
    queryProjectWarranty() {
      this.getAllProjects({
        page: this.currentPage,
        entry: this.perPage,
        search: this.search,
      })
        .then((data) => {
          this.processMetadata(data.metadata);
          this.isLoading = false;
        })
        .catch((e) => {
          this.$bvToast.toast("Load data failed, please reload the page.", {
            title: "Warning",
            variant: "danger",
            solid: true,
          });
          console.log(e);
        });
    },
    getBase64ImageFromURL(url) {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");

        img.onload = () => {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;

          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          var dataURL = canvas.toDataURL("image/png");

          resolve(dataURL);
        };

        img.onerror = (error) => {
          reject(error);
        };

        img.src = url;
      });
    },
    async letterDev(id) {
      const toDataURL = (url) =>
        fetch(url)
          .then((response) => response.blob())
          .then(
            (blob) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })
          );

      let getData = {};
      await this.getProjectWarranty(id)
        .then((res) => {
          getData = res;
          console.log(res);
        })
        .catch((e) => {
          console.log({ e });
        });

      function dateTransformWithDate(date) {
        let dates = new Date(date);
        let options = {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
          timeZone: "Asia/Singapore",
        };
        let formattedDate = dates
          .toLocaleDateString("en-SG", options)
          .replace(/,/g, ""); // Remove commas
        return formattedDate;
      }
      // let data = this.getDataProject
      console.log("pro", getData);
      let cert = `${getData.refNum}D`;
      // let monthText = getData.warrantyDuration === 15 ?'fifteen':'eighteen'
      let monthText = "";
      switch (getData.warrantyDuration) {
        case 15:
          monthText = "fifteen";
          break;
        case 18:
          monthText = "eighteen";
          break;
        default:
          monthText = "twelve";
          break;
      }
      let topDate = new Date(getData.topDate);
      let topDateString = new Date(getData.topDate);
      let transformTopDate = dateTransformWithDate(topDateString);
      let devEndDate = new Date(topDate);
      topDate.setDate(topDate.getDate() - 1);
      devEndDate = topDate.setMonth(
        topDate.getMonth() + getData.warrantyDuration
      );
      let transformDevEndDate = dateTransformWithDate(devEndDate);

      // var pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        // var pdfFonts = require("./customVfs.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      let date = new Date().toISOString().substring(0, 10);

      function dateTransform(date) {
        let dates = new Date(date);
        let options = {
          day: "numeric",
          month: "long",
          year: "numeric",
          timeZone: "Asia/Singapore",
        };
        let formattedDate = dates
          .toLocaleDateString("en-SG", options)
          .replace(/,/g, ""); // Remove commas
        return formattedDate;
      }

      // optimal width header 460
      var docDefinition = {
        pageMargins: [70, 120, 70, 50],
        header: function(currentPage, pageCount, pageSize) {
          return [
            {
              image: `${header}`,
              alignment: "center",
              width: 460,
              margin: [0, 50, 0, 0],
            },
          ];
        },
        footer: function(currentPage, pageCount) {
          return [
            {
              image: `${footer}`,
              width: 400,
              alignment: "center",
              margin: [0, 0, 0, 0],
            },
          ];
        },

        content: [
          { text: `Ref    : ${cert}`, style: "subheader" },
          { text: `Date  : ${date}` },
          { text: `\n` },
          { text: `\n` },
          { text: `${getData.developer}` },
          { text: `${getData.devAddress1} ${getData.devAddress2}` },
          { text: `${getData.devCountry} ${getData.devPostalCode}` },

          { text: `\n` },
          { text: `\n` },

          { text: `Dear Sirs \n` },
          { text: `\n` },

          {
            text: `WARRANTY FOR DAIKIN AIRCONDITIONING EQUIPMENT FOR ${getData.address.toUpperCase()} ${getData.country.toUpperCase()} ${getData.postalCode.toUpperCase()} \n`,
            fontSize: 12,
            bold: true,
          },
          { text: `\n` },
          {
            text: `We are pleased to advise the warranty for the airconditioning equipment installed at the above project shall commence from ${transformTopDate} with ${monthText} (${getData.warrantyDuration}) months warranty for the equipment.  
                    The warranty of all parts shall expire on ${transformDevEndDate}. \n`,
          },
          { text: `\n` },

          {
            text: `Please refer to Annex A for the list of items covered in this warranty. \n`,
          },
          { text: `\n` },

          {
            text: `Terms and Conditions of Warranty \n`,
            decoration: "underline",
            fontSize: 13,
            bold: true,
          },
          { text: `\n` },
          {
            text: `The warranty period for the air-conditioner is as follows –\n`,
          },
          { text: `\n` },
          {
            ol: this.terms,
            // ol:[
            //   ['The hermetically sealed Compressor shall be warranted for a further period immediately following the general warranty stipulated in the letter. Daikin will replace the compressor if, in Daikin’s judgment, it became inoperative as a result of defective materials.'],
            //   ['The warranty shall not apply to all parts or materials used for the replacement of defective parts of the air-conditioner pursuant to this Warranty.'],
            //   ['If the air-conditioner is defective in materials or workmanship during the applicable warranty period, Daikin will repair or replace such defective parts of the air-conditioner free of charge provided that the air-conditioner has been properly installed, used and maintained.  Whether the defective part will be repaired or replaced will rest solely in Daikin’s discretion.'],
            //   ['The compressor warranty does not cover the following costs –',
            //   {
            //     ul: [
            //       'Labour and transport',
            //       'Charging of refrigerant',
            //       'Other components within the refrigerant circuit',
            //     ]
            //   },
            //   ],
            //   ['The warranty shall become void under the following situations –',
            //   {
            //     ul: [
            //       'Tempering, alteration, modification, misuse or change in the air-conditioner in terms of design or function or set being',
            //       'Usage of any part in the air-conditioner other than genuine Daikin parts; or',
            //       'Defects or damage due to misuse or abuse, whether accidental or intentional, improper maintenance, use after a partial failure, use with a water supply of improper quality or electrical supply of improper voltage or frequency; or',
            //       'Damages resulting from accident, flood, fire, acts of God or other events beyond Daikin’s control; or',
            //       'If the air-conditioner is connected to other brand/make; or',
            //       'Defects or damage due to failure to follow Daikin’s instruction as regard the application, installation, use, service or maintenance, or to failure to comply with local codes, standards, ordinances or industry specifications.'
            //     ]
            //   },
            //   ],
            //   ['The Warranty also does not cover the following –',
            //   {
            //     ul: [
            //       `Electric bulbs, fuses, internal and external finishing`,
            //       `General servicing of the air-conditioner`,
            //     ]
            //   },
            //   ],
            //   [
            //     'In addition, Daikin will not render service or repair to air-conditioners under the following circumstances –',
            //   {
            //     ul: [
            //       'Units installed at inaccessible position',
            //       'Units installed at places where air is exposed to chemical fumes',
            //       'Workmanship problems found on external field piping and insulation materials',
            //     ]
            //   },
            //   ],
            //   [
            //     'Daikin’s responsibility under this Warranty ends if the original purchaser –',
            //   {
            //     ul: [
            //       'Relocates the air-conditioner',
            //       'Exports the air-conditioner out of Singapore',
            //     ]
            //   },
            //   ],

            // ]
          },
          { text: "\n" },
          { text: "\n" },
          { text: "General Manager for sales\n" },
          { text: "\n" },
          { text: "\n" },
          { text: "\n" },
          {
            text:
              "This is a system generated letter. No Signature is required.\n",
          },

          { text: "Annex A \n", bold: true, pageBreak: "before", fontSize: 13 },
          {
            style: "tableExample",
            color: "#444",
            table: {
              widths: ["20%", "20%", "30%", "30%"],
              // keepWithHeaderRows: 1,
              body: [
                [
                  {
                    text: "Model",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Serial No",
                    style: "tableHeader",
                    alignment: "center",
                    rowSpan: 2,
                    bold: true,
                  },
                  {
                    text: "Date of Warranty Expiry",
                    style: "tableHeader",
                    alignment: "center",
                    colSpan: 2,
                    bold: true,
                  },
                  {},
                ],
                [
                  {},
                  {},
                  { text: "Other Parts", bold: true, alignment: "center" },
                  { text: "Compressor", bold: true, alignment: "center" },
                ],
              ],
            },
          },
        ],
      };

      for (let i = 0; i < getData.itemsProject.length; i++) {
        const el = getData.itemsProject[i];
        let otherPart = dateTransform(el.generalEndWarranty);
        let compressor = el.compressorEndWarranty
          ? dateTransform(el.compressorEndWarranty)
          : "-";
        docDefinition.content[30].table.body.push([
          { text: `${el.itemModel}`, alignment: "center" },
          { text: `${el.serial}`, alignment: "center" },
          { text: `${otherPart}`, alignment: "center" },
          { text: `${compressor}`, alignment: "center" },
        ]);
      }
      pdfMake
        .createPdf(docDefinition)
        .download(`Daikin Warranty Letter-${cert}.pdf`);
    },

    async letterResident(id) {
      let getData = {};
      await this.getProjectWarranty(id)
        .then((res) => {
          getData = res;
          console.log(res);
        })
        .catch((e) => {
          console.log({ e });
        });

      function dateTransformWithDate(date) {
        let dates = new Date(date);
        let options = {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
          timeZone: "Asia/Singapore",
        };
        let formattedDate = dates
          .toLocaleDateString("en-SG", options)
          .replace(/,/g, ""); // Remove commas
        return formattedDate;
      }
      // let data = this.getDataProject
      console.log("dur", getData.itemsProject[0].residentialDuration);
      let cert = `${getData.refNum}R`;
      let resDur = getData.itemsProject[0].residentialDuration;
      let topDate = new Date(getData.topDate);
      let topDateString = new Date(getData.topDate);
      let transformTopDate = dateTransformWithDate(topDateString);

      let devEndDate = new Date(topDate);
      topDate.setDate(topDate.getDate() - 1);
      devEndDate = topDate.setMonth(
        topDate.getMonth() + getData.itemsProject[0].residentialDuration
      );
      let transformDevEndDate = dateTransformWithDate(devEndDate);

      // var pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        // var pdfFonts = require("./customVfs.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      let date = new Date().toISOString().substring(0, 10);

      function dateTransform(date) {
        return new Date(date).toUTCString().substring(5, 16);
      }

      // function number to words pronounce
      const ones = [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
      ];
      const tens = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ];
      const teens = [
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
      ];

      function convert_millions(num) {
        num = Number(num);
        if (num >= 1000000) {
          return (
            convert_millions(Math.floor(num / 1000000)) +
            " million " +
            convert_thousands(num % 1000000)
          );
        } else {
          return convert_thousands(num);
        }
      }

      function convert_thousands(num) {
        num = Number(num);
        if (num >= 1000) {
          return (
            convert_hundreds(Math.floor(num / 1000)) +
            " thousand " +
            convert_hundreds(num % 1000)
          );
        } else {
          return convert_hundreds(num);
        }
      }

      function convert_hundreds(num) {
        num = Number(num);
        if (num > 99) {
          return (
            ones[Math.floor(num / 100)] + " hundred " + convert_tens(num % 100)
          );
        } else {
          return convert_tens(num);
        }
      }

      function convert_tens(num) {
        console.log({ num });
        num = Number(num);
        if (num < 10) return ones[num];
        else if (num >= 10 && num < 20) return teens[num - 10];
        else {
          return tens[Math.floor(num / 10)] + " " + ones[num % 10];
        }
      }

      function convert(num) {
        num = Number(num);
        if (num == 0) return "zero";
        else return convert_millions(num);
      }
      //end of convertion code

      let monthText = convert(resDur);

      // optimal width header 460
      var docDefinition = {
        pageMargins: [70, 120, 70, 50],
        header: function(currentPage, pageCount, pageSize) {
          return [
            {
              image: `${header}`,
              alignment: "center",
              width: 460,
              margin: [0, 50, 0, 0],
            },
          ];
        },
        footer: function(currentPage, pageCount) {
          return [
            {
              image: `${footer}`,
              width: 400,
              alignment: "center",
              margin: [0, 0, 0, 0],
            },
          ];
        },

        content: [
          { text: `Ref    : ${cert}`, style: "subheader" },
          { text: `Date  : ${date}` },
          { text: `\n` },
          { text: `\n` },
          { text: `THE RESIDENTS` },
          { text: `${getData.address}` },
          { text: `${getData.country} ${getData.postalCode}` },

          { text: `\n` },
          { text: `\n` },

          { text: `Dear Sir/Madam \n` },
          { text: `\n` },

          {
            text: `WARRANTY FOR DAIKIN AIRCONDITIONING EQUIPMENT FOR ${getData.address.toUpperCase()} ${getData.country.toUpperCase()} ${getData.postalCode.toUpperCase()} \n`,
            fontSize: 12,
            bold: true,
          },
          { text: `\n` },
          {
            text: `We are pleased to advise the warranty for the airconditioning equipment installed at the above project shall commence from ${transformTopDate} with ${monthText} (${getData.itemsProject[0].residentialDuration}) months warranty for the equipment.  
                    The warranty of all parts shall expire on ${transformDevEndDate}. \n`,
          },
          { text: `\n` },

          // {text: `Please refer to Annex A for the list of items covered in this warranty. \n`},
          // {text: `\n`},

          {
            text: `Terms and Conditions of Warranty \n`,
            decoration: "underline",
            fontSize: 13,
            bold: true,
          },
          { text: `\n` },
          {
            text: `The warranty period for the air-conditioner is as follows –\n`,
          },
          { text: `\n` },
          {
            ol: this.terms,
            // ol:[
            //   'The hermetically sealed Compressor shall be warranted for a further period immediately following the general warranty stipulated in the letter. Daikin will replace the compressor if, in Daikin’s judgment, it became inoperative as a result of defective materials.',
            //   'The warranty shall not apply to all parts or materials used for the replacement of defective parts of the air-conditioner pursuant to this Warranty.',
            //   'If the air-conditioner is defective in materials or workmanship during the applicable warranty period, Daikin will repair or replace such defective parts of the air-conditioner free of charge provided that the air-conditioner has been properly installed, used and maintained.  Whether the defective part will be repaired or replaced will rest solely in Daikin’s discretion.',
            //   ['The compressor warranty does not cover the following costs –',
            //   {
            //     ul: [
            //       'Labour and transport',
            //       'Charging of refrigerant',
            //       'Other components within the refrigerant circuit',
            //     ]
            //   },
            //   ],
            //   ['The warranty shall become void under the following situations –',
            //   {
            //     ul: [
            //       'Tempering, alteration, modification, misuse or change in the air-conditioner in terms of design or function or set being',
            //       'Usage of any part in the air-conditioner other than genuine Daikin parts; or',
            //       'Defects or damage due to misuse or abuse, whether accidental or intentional, improper maintenance, use after a partial failure, use with a water supply of improper quality or electrical supply of improper voltage or frequency; or',
            //       'Damages resulting from accident, flood, fire, acts of God or other events beyond Daikin’s control; or',
            //       'If the air-conditioner is connected to other brand/make; or',
            //       'Defects or damage due to failure to follow Daikin’s instruction as regard the application, installation, use, service or maintenance, or to failure to comply with local codes, standards, ordinances or industry specifications.'
            //     ]
            //   },
            //   ],
            //   ['The Warranty also does not cover the following –',
            //   {
            //     ul: [
            //       `Electric bulbs, fuses, internal and external finishing`,
            //       `General servicing of the air-conditioner`,
            //     ]
            //   },
            //   ],
            //   [
            //     'In addition, Daikin will not render service or repair to air-conditioners under the following circumstances –',
            //   {
            //     ul: [
            //       'Units installed at inaccessible position',
            //       'Units installed at places where air is exposed to chemical fumes',
            //       'Workmanship problems found on external field piping and insulation materials',
            //     ]
            //   },
            //   ],
            //   [
            //     'Daikin’s responsibility under this Warranty ends if the original purchaser –',
            //   {
            //     ul: [
            //       'Relocates the air-conditioner',
            //       'Exports the air-conditioner out of Singapore',
            //     ]
            //   },
            //   ],

            // ]
          },
          { text: "\n" },
          { text: "\n" },
          {
            text:
              "This is a system generated letter. No Signature is required.\n",
          },
          { text: "\n" },
          { text: "\n" },
          { text: "\n" },
          { text: "General Manager for sales\n" },

          //     {text: 'Annex A \n',
          //     bold: true,
          //     pageBreak: 'before',
          //     fontSize: 13},
          // {
          // 	style: 'tableExample',
          // 	color: '#444',
          // 	table: {
          // 		widths: ['20%', '20%', '30%','30%',],
          // 		// keepWithHeaderRows: 1,
          // 		body: [
          // 			[{text: 'Model', style: 'tableHeader', alignment: 'center',rowSpan:2,bold:true},
          // 			{text: 'Serial No', style: 'tableHeader', alignment: 'center',rowSpan:2,bold:true},
          // 			{text: 'Date of Warranty Expiry', style: 'tableHeader', alignment: 'center', colSpan:2,bold:true},
          // 			{},
          // 			],
          // 			[{},{},{text: 'Other Parts',bold:true, alignment: 'center'},{text: 'Compressor',bold:true, alignment: 'center'},]

          // 		]
          // 	}
          // },
        ],
      };

      // for (let i = 0; i < getData.itemsProject.length; i++) {
      //   const el = getData.itemsProject[i];
      //   let otherPart = dateTransform(el.residentialEndWarranty)
      //   let compressor = el.compressorEndWarranty? dateTransform(el.compressorEndWarranty):'-'
      //   docDefinition.content[30].table.body.push([
      //     {text:`${el.itemModel}`, alignment:'center' },
      //     {text:`${el.serial}`, alignment:'center' },
      //     {text:`${otherPart}`, alignment:'center' },
      //     {text:`${compressor}`, alignment:'center' },
      //   ])

      // }
      pdfMake
        .createPdf(docDefinition)
        .download(`Daikin Warranty Letter-${cert}.pdf`);
    },
    addProject() {
      this.$router.push(`/warranty/project-warranty/new`);
    },
    doEditProject(idProject) {
      this.$router.push(`/warranty/project-warranty/edit/${idProject}`);
    },
    doDelete() {
      // this.deleteProject(this.selectProject._id)
      axios
        .delete(`sales-and-purchase/project-warranty/${this.selectProject._id}`)
        .then(async (project) => {
          console.log({ project });
          this.project = project;
          this.$bvToast.toast(`Delete Project Warranty Successfully`, {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.getAllProjects();
          this.modalDelete = false;
        })
        .catch((e) => {
          console.log({ e });
          if (e.request.status === 400) {
            this.$bvToast.toast(`${e.response.data.errors[0].message}`, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast(`${e.response.data.message}`, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
        });
    },
    deleteProject(row) {
      this.selectProject = row;
      this.modalDelete = true;
    },
  },
  computed: {
    ...mapState({
      //projects: (state) => state.projectWarranty.projects,
      termsCondition: (state) => state.TermCondition,
    }),
    projects() {
      console.info(this.$store.getters["projectWarranty/getProjectWarranties"]);
      return this.$store.getters["projectWarranty/getProjectWarranties"];
    },
    terms() {
      let final = [];
      console.log(this.termsCondition);
      let primary = this.termsCondition.TermCondition[0].primary;
      let secondary = this.termsCondition.TermCondition[0].secondary;
      for (let i = 0; i < primary.length; i++) {
        let temp = [];
        temp.push(primary[i]);
        if (secondary[i].length !== 0) {
          temp.push({ ul: secondary[i] });
        }
        final.push(temp);
      }
      console.log({ final });
      return final;
    },
    permission() {
      return userAccess("Project Warranty", "warranty_menu");
    },
    dataSearch() {
      let paginationLength;
      paginationLength = this.filteredProject;
      if (this.search != "") {
        return paginationLength.length;
      } else {
        return this.projects.length;
      }
    },
  },
  created() {
    document.title = "Project Warranty | RSP";
  },
};
</script>
